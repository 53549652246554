import * as i0 from '@angular/core';
import { ChangeDetectorRef, Component, ChangeDetectionStrategy, Optional, Self, Inject, ViewChild, Input, NgModule } from '@angular/core';
import * as i3 from '@angular/forms';
import { NgControl, FormsModule } from '@angular/forms';
import * as i4 from '@taiga-ui/cdk';
import { AbstractTuiControl, tuiIsNativeFocused, tuiAsFocusableItemAccessor, TuiFocusableModule, TuiFocusedModule } from '@taiga-ui/cdk';
import { TUI_VALUE_ACCESSOR_PROVIDER } from '@taiga-ui/kit/providers';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i2 from '@taiga-ui/core';
import { TuiMaskAccessorModule } from '@taiga-ui/core';
const _c0 = ["native"];
const _c1 = ["text"];
const _c2 = ["*"];
function TuiInputInlineComponent_span_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 5);
    i0.ɵɵprojection(1);
    i0.ɵɵelementEnd();
  }
}
class TuiInputInlineComponent extends AbstractTuiControl {
  constructor(control, cdr) {
    super(control, cdr);
    this.maxLength = null;
    this.indent = -1;
  }
  get nativeFocusableElement() {
    return !this.native ? null : this.native.nativeElement;
  }
  get focused() {
    return tuiIsNativeFocused(this.nativeFocusableElement);
  }
  get hasValue() {
    return this.value !== '';
  }
  onValueChange(value) {
    if (!this.text) {
      return;
    }
    // Sync update so width is calculated immediately
    this.text.nativeElement.textContent = value;
    this.text.nativeElement.style.textIndent = '';
    this.value = value;
  }
  onFocused(focused) {
    this.updateFocused(focused);
  }
  onScroll() {
    var _a;
    const indent = ((_a = this.native) === null || _a === void 0 ? void 0 : _a.nativeElement.scrollLeft) || 0;
    // -1 for Safari (see styles)
    this.indent = -1 - indent;
  }
  getFallbackValue() {
    return '';
  }
}
TuiInputInlineComponent.ɵfac = function TuiInputInlineComponent_Factory(t) {
  return new (t || TuiInputInlineComponent)(i0.ɵɵdirectiveInject(NgControl, 10), i0.ɵɵdirectiveInject(ChangeDetectorRef));
};
TuiInputInlineComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TuiInputInlineComponent,
  selectors: [["tui-input-inline"]],
  viewQuery: function TuiInputInlineComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c0, 5);
      i0.ɵɵviewQuery(_c1, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.native = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.text = _t.first);
    }
  },
  inputs: {
    maxLength: "maxLength"
  },
  features: [i0.ɵɵProvidersFeature([TUI_VALUE_ACCESSOR_PROVIDER, tuiAsFocusableItemAccessor(TuiInputInlineComponent)]), i0.ɵɵInheritDefinitionFeature],
  ngContentSelectors: _c2,
  decls: 5,
  vars: 10,
  consts: [["text", ""], ["native", ""], [1, "t-before", 3, "textContent"], ["automation-id", "tui-input-inline__placeholder", "class", "t-placeholder", 4, "ngIf"], ["automation-id", "tui-input-inline__native", "tuiMaskAccessor", "", "type", "text", 1, "t-native", 3, "ngModelChange", "scroll", "tuiFocusedChange", "disabled", "id", "ngModel", "readOnly", "tuiFocusable"], ["automation-id", "tui-input-inline__placeholder", 1, "t-placeholder"]],
  template: function TuiInputInlineComponent_Template(rf, ctx) {
    if (rf & 1) {
      const _r1 = i0.ɵɵgetCurrentView();
      i0.ɵɵprojectionDef();
      i0.ɵɵelement(0, "span", 2, 0);
      i0.ɵɵtemplate(2, TuiInputInlineComponent_span_2_Template, 2, 0, "span", 3);
      i0.ɵɵelementStart(3, "input", 4, 1);
      i0.ɵɵlistener("ngModelChange", function TuiInputInlineComponent_Template_input_ngModelChange_3_listener($event) {
        i0.ɵɵrestoreView(_r1);
        return i0.ɵɵresetView(ctx.onValueChange($event));
      })("scroll", function TuiInputInlineComponent_Template_input_scroll_3_listener() {
        i0.ɵɵrestoreView(_r1);
        return i0.ɵɵresetView(ctx.onScroll());
      })("tuiFocusedChange", function TuiInputInlineComponent_Template_input_tuiFocusedChange_3_listener($event) {
        i0.ɵɵrestoreView(_r1);
        return i0.ɵɵresetView(ctx.onFocused($event));
      });
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵstyleProp("text-indent", ctx.indent, "px");
      i0.ɵɵproperty("textContent", ctx.value);
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("ngIf", !ctx.hasValue);
      i0.ɵɵadvance();
      i0.ɵɵproperty("disabled", ctx.computedDisabled)("id", ctx.id)("ngModel", ctx.value)("readOnly", ctx.readOnly)("tuiFocusable", ctx.focusable);
      i0.ɵɵattribute("maxLength", ctx.maxLength);
    }
  },
  dependencies: [i1.NgIf, i2.TuiMaskAccessorDirective, i3.DefaultValueAccessor, i3.NgControlStatus, i3.NgModel, i4.TuiFocusableDirective, i4.TuiFocusedDirective],
  styles: ["[_nghost-%COMP%]{position:relative;display:inline-block;overflow:hidden;white-space:nowrap;box-sizing:border-box}.t-before[_ngcontent-%COMP%]{display:block;padding-right:.02em;margin-left:1px;white-space:pre}.t-placeholder[_ngcontent-%COMP%]{display:inline-block;min-width:1px}.t-native[_ngcontent-%COMP%]{position:absolute;top:0;left:0;background-color:transparent;padding:inherit;font:inherit;-webkit-text-fill-color:transparent;box-sizing:border-box;width:100%;height:100%;border-width:0;text-align:inherit;letter-spacing:inherit;text-indent:inherit;text-transform:inherit;outline:none}"],
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiInputInlineComponent, [{
    type: Component,
    args: [{
      selector: 'tui-input-inline',
      templateUrl: './input-inline.template.html',
      styleUrls: ['./input-inline.style.less'],
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [TUI_VALUE_ACCESSOR_PROVIDER, tuiAsFocusableItemAccessor(TuiInputInlineComponent)]
    }]
  }], function () {
    return [{
      type: i3.NgControl,
      decorators: [{
        type: Optional
      }, {
        type: Self
      }, {
        type: Inject,
        args: [NgControl]
      }]
    }, {
      type: i0.ChangeDetectorRef,
      decorators: [{
        type: Inject,
        args: [ChangeDetectorRef]
      }]
    }];
  }, {
    native: [{
      type: ViewChild,
      args: ['native']
    }],
    text: [{
      type: ViewChild,
      args: ['text']
    }],
    maxLength: [{
      type: Input
    }]
  });
})();
class TuiInputInlineModule {}
TuiInputInlineModule.ɵfac = function TuiInputInlineModule_Factory(t) {
  return new (t || TuiInputInlineModule)();
};
TuiInputInlineModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiInputInlineModule
});
TuiInputInlineModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, FormsModule, TuiFocusableModule, TuiFocusedModule, TuiMaskAccessorModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiInputInlineModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, FormsModule, TuiFocusableModule, TuiFocusedModule, TuiMaskAccessorModule],
      declarations: [TuiInputInlineComponent],
      exports: [TuiInputInlineComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiInputInlineComponent, TuiInputInlineModule };
